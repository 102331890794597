import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

import Slider from 'react-slick';

import CaliceoButton from 'components/buttons/CaliceoButton';
import QUICK_ACCESS_BLOCK_QUERY from 'hooks/use-quick-access-block-content';
import CHECK_CENTERS_BY_AQUAO from 'hooks/use-get-centers-aquao';
import { getCenterByDomainAccess } from 'utils/getCentersBy';
import ResearchModule from '../research/ResearchModule';
import ChooseOffersModal from './ChooseOffersModal/ChooseOffersModal';

import QuickAccessOffer from './QuickAccessOffer';
// import QuickAccessEvent from './QuickAccessEvent';

import './quickAccessBlock.scss';

import forwardToCenter from 'utils/forwardToCenter';
import { getHostname } from 'utils/location';

const QuickAccessBlock = (props) => {
  const { centers, location } = props;

  const { data, loading, error } = useQuery(QUICK_ACCESS_BLOCK_QUERY);

  const hostname = getHostname(location);

  const [showOffersModal, setShowOffersModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [centerObj, setCenterObj] = useState(null);
  const [quickAccessBlockIds, setQuickAccessBlockIds] = useState([]);
  const [offerAquaoOids, setOfferAquaoOids] = useState(null);
  const [validDomainAccesses, setValidDomainAccesses] = useState([]);
  const [displayAllModalCenters, setDisplayAllModalCenters] = useState(true);
  const [selectedOid, setSelectedOid] = useState('');

  const [checkCenters] = useLazyQuery(CHECK_CENTERS_BY_AQUAO, {
    returnPartialData: true,
    onCompleted: (completedData) => setOfferAquaoOids(completedData),
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleOfferClick = (e, selectedVariantOid, offerObj) => {
    e.stopPropagation();
    checkCenters({
      variables: {
        aquaoOid: offerObj.fieldAquaoOid,
      },
    });
    setDisplayAllModalCenters(false);
    setSelectedOffer(offerObj);
    setSelectedOid(selectedVariantOid);
  };

  useEffect(() => {
    setCenterObj(getCenterByDomainAccess(
      centers, selectedOffer?.fieldDomainAccess[0].entity.entityId,
    ));
  }, [selectedOffer, centers]);

  useEffect(() => {
    if (offerAquaoOids) {
      const oidHasNoCenters = () => offerAquaoOids?.nodeQuery.entities.every((offer) => {
        return offer.entityBundle === 'variante';
      });
      if (oidHasNoCenters()) {
        forwardToCenter(centers, centerObj?.centerId, hostname, `/produit/${selectedOffer?.nid}`);
      } else {
        const getValidDomainAccesses = offerAquaoOids?.nodeQuery.entities.flatMap(
          (a) => (a.fieldDomainAccess ? a : []),
        );
        setValidDomainAccesses(getValidDomainAccesses);
        setShowOffersModal(true);
      }
    }
  }, [offerAquaoOids, centerObj, selectedOffer, hostname, centers]);

  const handleEventClick = (e, eventItem) => {
    e.stopPropagation();
    navigate(`/node/${eventItem.nid}`);
  };

  const getQuickAccessIds = (item) => {
    const urlFragments = item.uri.split('/');
    const itemAquaoOidFragments = urlFragments[2].split('?');
    const qs = queryString.parse(itemAquaoOidFragments[1]);
    const urlHasQuery = urlFragments[2]?.includes('?');
    return ({
      itemUrl: item.url.path,
      itemNid: urlHasQuery ? itemAquaoOidFragments[0] : urlFragments[2],
      selectedVariantOid: urlHasQuery ? qs : '',
    });
  };

  useEffect(() => {
    if (data) {
      const slicedNodeIds = data?.blockContentById.fieldContenuLien.map(
        (item) => getQuickAccessIds(item),
      );
      setQuickAccessBlockIds(slicedNodeIds);
    }
  }, [data]);

  const openCentersModal = (e) => {
    e.stopPropagation();
    setShowOffersModal(true);
    setDisplayAllModalCenters(true);
  };

  return (
    <div className="quick-access--wrapper">
      <div className="container position-relative">
        <ResearchModule location={location} selectedCenter={centerObj} buttonClass="secondary-caliceo--button" />
        {/* <div className="quick-access--row d-none d-md-flex"> */}
          {/* {quickAccessBlockIds.map((quickAccessBlockItem) => (
            <QuickAccessOffer
              handleOfferClick={handleOfferClick}
              key={quickAccessBlockItem.itemUrl}
              offer={quickAccessBlockItem}
              openCentersModal={openCentersModal}
              handleEventClick={handleEventClick}
            />
            // return (
            //   <QuickAccessEvent
            //     handleEventClick={handleEventClick}
            //     openCentersModal={openCentersModal}
            //     key={quickAccessBlockItem.itemUrl}
            //     event={quickAccessBlockItem}
            //     centers={centers}
            //   />
            // );
          ))} */}
        {/* </div> */}
        {/* <div className="quick-access--row d-flex d-md-none"> */}
          {/* <Slider {...settings} className="quick-access-row--slider">
            {quickAccessBlockIds.map((quickAccessBlockItem) => (
              <QuickAccessOffer
                handleOfferClick={handleOfferClick}
                openCentersModal={openCentersModal}
                key={quickAccessBlockItem.itemUrl}
                offer={quickAccessBlockItem}
              />
            ))}
          </Slider> */}
        {/* </div> */}
        {showOffersModal && (
          <ChooseOffersModal
            offerName={selectedOffer?.title}
            offer={selectedOffer}
            validDomainAccesses={validDomainAccesses}
            showOffersModal={showOffersModal}
            setShowOffersModal={setShowOffersModal}
            centers={centers}
            displayAllModalCenters={displayAllModalCenters}
            selectedOid={selectedOid}
            hostname={hostname}
          />
        )}
      </div>
    </div>
  );
};

export default QuickAccessBlock;
