import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import RightArrow from 'components/icon-components/RightArrow';

const CenterBlockListItem = (props) => {
  const [itemHovered, setItemHovered] = useState(false);

  const { title, imgUrl, url, forwardToCenter } = props;

  return (
    <button
      className="center-block-list--item"
      onMouseOver={() => setItemHovered(true)}
      onMouseLeave={() => setItemHovered(false)}
      type="button"
      onClick={() => forwardToCenter(url)}
    >
      <div className="center-block-list--content">
        <div className="center-block-list--item--image-wrapper">
          <img className="center-block-list--item--image" src={imgUrl} alt={`Center-${title}`} />
        </div>
        <h3 className="center-block-list--item--title">{title}</h3>
      </div>
      <RightArrow fill={itemHovered ? '#ffffff' : '#8bacdb'} />
    </button>
  );
};

CenterBlockListItem.defaultProps = {
  url: '/',
};

CenterBlockListItem.propTypes = {
  url: PropTypes.string,
  imgUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CenterBlockListItem;
