import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import queryString from 'query-string';
import { useLazyQuery } from '@apollo/react-hooks';

import useOffersBlockContent from 'hooks/use-offers-block-content';

import './ourOffers.scss';
import CaliceoButton from 'components/buttons/CaliceoButton';
import ChooseOffersModal from 'components/home-group/quick-access-block/ChooseOffersModal/ChooseOffersModal';
import OffersRightArrow from 'components/offer-slider-arrows/OffersRightArrow';
import OffersLeftArrow from 'components/offer-slider-arrows/OffersLeftArrow';

import forwardToCenter from 'utils/forwardToCenter';
import { getHostname } from 'utils/location';
import { getCenterByDomainAccess } from 'utils/getCentersBy';
import CHECK_CENTERS_BY_AQUAO from 'hooks/use-get-centers-aquao';

import OfferSlide from './OfferSlide';

const OurOffers = (props) => {
  const { location, centers } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoPlay: true,
    nextArrow: <OffersRightArrow />,
    prevArrow: <OffersLeftArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };

  const hostname = getHostname(location);

  const [showOffersModal, setShowOffersModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState('');
  const [validDomainAccesses, setValidDomainAccesses] = useState([]);
  const [selectedOid, setSelectedOid] = useState('');
  const [landingOffers, setLandingOffers] = useState([]);
  const [centerObj, setCenterObj] = useState(null);
  const [offerAquaoOids, setOfferAquaoOids] = useState(null);
  const [displayAllModalCenters, setDisplayAllModalCenters] = useState(true);

  const offers = useOffersBlockContent();

  const [checkCenters] = useLazyQuery(CHECK_CENTERS_BY_AQUAO, {
    returnPartialData: true,
    onCompleted: (completedData) => setOfferAquaoOids(completedData),
  });

  const handleOfferClick = (e, selectedVariantOid, offerObj) => {
    e.stopPropagation();
    checkCenters({
      variables: {
        aquaoOid: offerObj.fieldAquaoOid,
      },
    });
    setDisplayAllModalCenters(false);
    setSelectedOffer(offerObj);
    setSelectedOid(selectedVariantOid);
  };

  const getLandingOfferIds = (offer) => {
    const urlFragments = offer.offerUrl.split('/');
    const offerAquaoOidFragments = urlFragments[2].split('?');
    const qs = queryString.parse(offerAquaoOidFragments[1]);
    const urlHasQuery = urlFragments[2]?.includes('?');
    return ({
      offerUrl: offer.offerUrl,
      offerNid: urlHasQuery ? offerAquaoOidFragments[0] : urlFragments[2],
      offerAquaoOid: urlHasQuery ? qs : '',
      promotedOffer: offer.promotedOffer,
    });
  };

  useEffect(() => {
    const slicedNodeIds = offers.map((offer) => getLandingOfferIds(offer));
    setLandingOffers(slicedNodeIds);
  }, []);

  useEffect(() => {
    if (selectedOffer) {
      setCenterObj(getCenterByDomainAccess(
        centers, selectedOffer?.fieldDomainAccess[0].entity.entityId,
      ));
    }
  }, [selectedOffer, centers]);

  useEffect(() => {
    if (offerAquaoOids) {
      const oidHasNoCenters = () => offerAquaoOids?.nodeQuery.entities.every((offer) => {
        return offer.entityBundle === 'variante';
      });
      if (oidHasNoCenters()) {
        forwardToCenter(centers, centerObj?.centerId, hostname, `/produit${selectedOffer?.path.alias}`);
      } else {
        const getValidDomainAccesses = offerAquaoOids?.nodeQuery.entities.flatMap(
          (a) => (a.fieldDomainAccess ? a : []),
        );
        setValidDomainAccesses(getValidDomainAccesses);
        setShowOffersModal(true);
      }
    }
  }, [offerAquaoOids, centerObj, selectedOffer, hostname, centers]);

  const openCentersModal = (e) => {
    e.stopPropagation();
    setShowOffersModal(true);
    setDisplayAllModalCenters(true);
  };

  return (
    <div className="container">
      <h2 className="our-offers--title">Nos offres incontournables</h2>
      <p className="our-offers--desc">Découvrir nos offres les plus appréciées</p>
      <Slider {...settings} className="offers-slider">
        {landingOffers?.map((offer) => (
          <OfferSlide
            key={offer.offerUrl}
            offer={offer}
            isPromo={offer.promotedOffer}
            handleOfferClick={handleOfferClick}
          />
        ))}
      </Slider>
      <CaliceoButton
        primaryButton
        onClick={openCentersModal}
        classNames="all-offers-button"
        title="Découvrir toutes nos offres"
      />
      {showOffersModal && (
        <ChooseOffersModal
          offerName={selectedOffer?.title}
          showOffersModal={showOffersModal}
          displayAllModalCenters={displayAllModalCenters}
          setShowOffersModal={setShowOffersModal}
          validDomainAccesses={validDomainAccesses}
          offer={selectedOffer}
          centers={centers}
          hostname={hostname}
          selectedOid={selectedOid}
        />
      )}
    </div>
  );
};

export default OurOffers;
