import { useStaticQuery, graphql } from 'gatsby';

const useOurUniverseContent = () => {
  const { caliceo } = useStaticQuery(graphql`
    query {
      caliceo {
        blockContentById(id: "8") {
          ... on caliceo_BlockContentSimple {
            entityId
            entityLabel
            fieldTitre
            fieldSousTitre
          }
        }
        taxonomyTermQuery(
          filter: { conditions: [{ field: "vid", value: "univers" }] }
        ) {
          entities {
            entityId
            entityLabel
            ... on caliceo_TaxonomyTermUnivers {
              fieldAccrocheTitre
              fieldAccrocheSoustitre
              fieldAccrocheTexteCourt {
                processed
              }
              fieldAccrocheLien {
                title
                url {
                  path
                }
                uri
              }
              fieldAccrocheFondImage {
                entity {
                  ... on caliceo_MediaImage {
                    fieldMediaImage {
                      entity {
                        filename
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const universeMainContent = {
    title: caliceo.blockContentById.fieldTitre,
    subtitle: caliceo.blockContentById.fieldSousTitre,
  };

  const universeCards = caliceo.taxonomyTermQuery.entities.map(
    (entity) => ({
      title: entity.fieldAccrocheTitre,
      subtitle: entity.fieldAccrocheSoustitre,
      description: entity.fieldAccrocheTexteCourt?.processed,
      link: {
        url: entity.fieldAccrocheLien?.url.path,
        title: entity.fieldAccrocheLien?.title,
        uri: entity.fieldAccrocheLien?.uri
      },
      imgUrl: entity.fieldAccrocheFondImage?.entity.fieldMediaImage.entity.url,
    }),
  );

  return {
    universeMainContent,
    universeCards,
  };
};

export default useOurUniverseContent;
