import React from 'react';
import useLandingHeaderContent from 'hooks/use-landing-header-content';

import HeroVideoHeader from './HeroVideoHeader';
import HeroCarouselHeader from './HeroCarouselHeader';
import HeroImageHeader from './HeroImageHeader';

const GroupHeader = () => {
  const { landingData } = useLandingHeaderContent();

  if (landingData.landingVideo) {
    return (
      <HeroVideoHeader
        videoUrl={landingData.landingVideo.entity.fieldMediaOembedVideo}
        heroHeading={landingData.landingTitle}
        heroLink={landingData.landingUrl}
      />
    );
  }
  if (landingData.images.length > 1) {
    return (
      <HeroCarouselHeader
        heroHeading={landingData.landingTitle}
        heroLink={landingData.landingUrl}
        carouselItems={landingData.images}
      />
    );
  }
  return (
    <HeroImageHeader
      heroHeading={landingData.landingTitle}
      heroLink={landingData.landingUrl}
      heroImage={landingData.images ? landingData.images[0] : ''}
    />
  );
};

export default GroupHeader;
