import { useStaticQuery, graphql } from 'gatsby';

const useCenterBlockContent = () => {
  const { caliceo } = useStaticQuery(graphql`
    query {
      caliceo {
        blockContentById(id: "5") {
          ... on caliceo_BlockContentSimple {
            entityId
            entityLabel
            fieldTitre
            fieldSousTitre
          }
        }
        nodeQuery(
          filter: { conditions: [{ field: "type", value: "centre" }] }
        ) {
          entities {
            entityId
            entityLabel
            entityBundle
            ... on caliceo_NodeCentre {
              fieldDomainAccess {
                entity {
                  entityId
                }
              }
              fieldCentreid
              fieldAccrocheImage {
                entity {
                  ... on caliceo_MediaImage {
                    fieldMediaImage {
                      entity {
                        filename
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const centerBlockMainContent = {
    title: caliceo.blockContentById.fieldTitre,
    description: caliceo.blockContentById.fieldSousTitre,
  };

  const centerBlockListItems = caliceo.nodeQuery.entities.map((entity) => ({
    url: entity.fieldCentreid,
    centerId: entity.fieldCentreid,
    title: entity.entityLabel,
    imgUrl: entity.fieldAccrocheImage.entity.fieldMediaImage.entity.url,
  }));

  return {
    centerBlockMainContent,
    centerBlockListItems,
  };
};

export default useCenterBlockContent;
