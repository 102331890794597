export const geoUrl = 'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';

export const markers = [
  {
    markerOffsetY: 35,
    markerOffsetX: 13,
    name: 'Nantes - St-Herblain',
    coordinates: [-1.58203125, 47.21956811231547],
    centerId: 'centre-nantes',
  },
  {
    markerOffsetY: 35,
    markerOffsetX: 13,
    name: 'Bordeaux - Bruges',
    coordinates: [-0.59326171875, 44.84029065139799],
    centerId: 'centre-bordeaux',
  },
  {
    markerOffsetY: 35,
    markerOffsetX: 13,
    name: 'Toulouse - L\'Union',
    coordinates: [1.4501953125, 43.61221676817573],
    centerId: 'centre-toulouse',
  },
  {
    markerOffsetY: 35,
    markerOffsetX: 13,
    name: 'Perpignan - Saleilles',
    coordinates: [2.3999042968749996, 43.00665956351041],
    centerId: 'centre-perpignan',
  },
  {
    markerOffsetY: 35,
    markerOffsetX: 13,
    name: 'Pau',
    coordinates: [-0.37353515625, 43.30119623257966],
    centerId: 'centre-pau',
  },
  {
    markerOffsetY: 35,
    markerOffsetX: 13,
    name: 'Ste-Foy-lès-Lyon',
    coordinates: [4.804827, 45.735237],
    centerId: 'centre-lyon',
  },
  {
    name: 'Saint-Cyr-l\'École',
    centerId: 'centre-saintcyrlecole',
  },
  {
    name: 'Lieusaint',
    centerId: 'centre-lieusaint',
  },
  {
    name: 'Cormeilles-en-Parisis',
    centerId: 'centre-cormeillesenparisis',
  },
  // {
  //   markerOffsetY: 35, markerOffsetX: 10, name: 'Lille', coordinates: [2.776171875, 50.43901028125873],
  // },
  // {
  //   markerOffsetY: 25, markerOffsetX: -30, name: 'Metz', coordinates: [6.185302734375, 49.1314084139986],
  // },
  // {
  //   markerOffsetY: 45, markerOffsetX: 10, name: 'Paris', coordinates: [2.35107421875, 48.88277959345126],
  // },
];
