import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';

import LandingHeader from 'components/home-group/header/LandingHeader';
import QuickAccessBlock from 'components/home-group/quick-access-block';
import CenterBlock from 'components/home-group/center-block/CenterBlock';
import OurOffers from 'components/home-group/our-offers';
// import SimplePushBlock from 'components/home-group/simple-push-block/SimplePushBlock';
import OurUniverse from 'components/home-group/our-universe/OurUniverse';
import ComplexPushBlock from 'components/home-group/complex-push-block/ComplexPushBlock';
import Reinsurance from 'components/home-group/reinsurance/Reinsurance';

import { getCenterName } from 'utils/location';
import Centers from './centers';

const IndexPage = (props) => {
  const [showHome, setShowHome] = useState(true);
  const centerName = getCenterName(props.location);

  useEffect(() => {
    if (centerName?.includes('localhost') || centerName?.includes('caliceo') || centerName?.includes('fariskunic')) {
      setShowHome(true);
    } else {
      setShowHome(false);
    }
  }, []);
  console.log(centerName);
  if (showHome) {
    return (
      <Layout location={props.location}>
        <SEO title="Calicéo - Espace de remise en forme aquatique et beauté massages" description="Votre parenthèse bien-être commence ici. Découvrez les centres Calicéo proches de chez vous et réservez votre expérience détente en toute tranquillité." center={centerName}/>
        <LandingHeader />
        <QuickAccessBlock />
        <CenterBlock centerName={centerName} />
        <OurOffers />
        {/* <SimplePushBlock /> */}
        <OurUniverse />
        <ComplexPushBlock />
        <Reinsurance location={props.location} />
        {/* <BookingModal /> */}
      </Layout>
    );
  }
  return <Centers location={props.location} hostname={centerName} />;
};

export default IndexPage;
