import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';

import CaliceoLogo from 'components/icon-components/CaliceoLogo';

import carteCaliceo from 'images/carte-caliceo.svg';

import { geoUrl, markers } from './utils';

const CenterBlockMap = (props) => {

  //console.log(geoUrl);

  const { forwardToCenter } = props;
  return (
    <>
      <div className="cont_map">
        <img src={carteCaliceo} className="carte-caliceo" />
        {markers.map(({
          name, centerId,
        }) => (
          <div className={"un_centre "+centerId} onClick={() => forwardToCenter(centerId)}>
            <CaliceoLogo />
            <p>{name}</p>
          </div>
        ))}
      </div>
      
    </>
    
  );
};

export default CenterBlockMap;
