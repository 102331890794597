import { useStaticQuery, graphql } from 'gatsby';

const useOffersBlockContent = () => {
  const { caliceo } = useStaticQuery(graphql`
    query {
      caliceo {
        blockContentById(id: "3") {
          ... on caliceo_BlockContentReferencesPromues {
            entityId
            entityLabel
              fieldContenuLien {
              url {
                path
              }
              uri
            }
            fieldContenuPromoLien {
              url {
                path
              }
              uri
            }
          }
        }
      }
    }
  `);

  const regularOffers = caliceo.blockContentById.fieldContenuLien.map((regularOffer) => ({
    offerUrl: regularOffer.uri,
    promotedOffer: false,
  }));

  const promoOffers = caliceo.blockContentById.fieldContenuPromoLien.map((promoOffer) => ({
    offerUrl: promoOffer?.uri,
    promotedOffer: true,
  }));

  let index = 2;

  Array.from(promoOffers).forEach((v) => {
    regularOffers.splice(index, 0, v);
    index += 2;
  });

  return regularOffers;
};

export default useOffersBlockContent;
