import React, { useState } from 'react';
import Slider from 'react-slick';
import useCenterBlockContent from 'hooks/use-center-block-content';

import CaliceoLogoUpper from 'images/caliceo-logo-center-block.png';
import CaliceoLogoRight from 'images/caliceo-logo-center-block-right.png';
import forwardToCenter from 'utils/forwardToCenter';
import { getHostname } from 'utils/location';
import fl_left from 'images/arrow-blue-circle.svg';
import CenterBlockListItem from './CenterBlockListItem';
import CenterBlockSlideItem from './CenterBlockSlideItem';
import CenterBlockMap from './CenterBlockMap';

import './centerBlock.scss';

const CenterBlock = (props) => {
  const centerBlockContent = useCenterBlockContent();
  const [centersViewMode, setCentersViewMode] = useState('list');

  const hostname = getHostname(props.location);

  const {
    centerBlockMainContent: {
      title,
      description,
    },
    centerBlockListItems,
  } = centerBlockContent;

  // console.log(centerBlockListItems);
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <img src={fl_left} />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <img src={fl_left} />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    rows: 3,
    slidesToShow: 2,
    slidesPerRow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    vertical: false,
  };

  const forwardTo = (_center) => {
    forwardToCenter(centerBlockListItems, _center, hostname);
  };

  return (
    <div className={`center-block--wrapper ${centersViewMode === 'list' ? 'list--active' : 'map--active'}`}>
      <img src={CaliceoLogoUpper} alt="" className="center-block--upper-image" />
      <img src={CaliceoLogoRight} alt="" className="center-block--right-image" />
      <div className="container">
        <div className="position-relative">
          <h2 className="center-block--title">{title}</h2>
          <div className="center-block--row">
            <h3 className="center-block--description">{description}</h3>
            <div className="d-none d-md-block">
              <button type="button" className={`center-block--button ${centersViewMode === 'list' ? 'list--active' : 'list--inactive'}`} onClick={() => setCentersViewMode('list')}>Liste</button>
              <button type="button" className={`center-block--button ${centersViewMode === 'map' ? 'map--active' : 'map--inactive'}`} onClick={() => setCentersViewMode('map')}>Carte</button>
            </div>
          </div>
        </div>
        <div className="d-none d-md-block">
          {centersViewMode === 'list' ? (
            <div className="center-block-list--wrapper">
              {centerBlockListItems.map((centerBlockListItem) => {
                // console.log(forwardTo);
                if (centerBlockListItem.title !== 'Paris') {
                  return (
                    <CenterBlockListItem
                      key={centerBlockListItem.title}
                      title={centerBlockListItem.title}
                      imgUrl={centerBlockListItem.imgUrl}
                      url={centerBlockListItem.url}
                      forwardToCenter={forwardTo}
                    />
                  );
                }
                return null;
              })}
            </div>
          ) : (
            <CenterBlockMap forwardToCenter={forwardTo} />
          )}
        </div>
        <div className="d-block d-md-none">
          <div className="center_deux_col_mob">
            <CenterBlockSlideItem
              key={centerBlockListItems[8].title}
              title={centerBlockListItems[8].title}
              imgUrl={centerBlockListItems[8].imgUrl}
              url={centerBlockListItems[8].url}
              forwardToCenter={forwardTo}
            />

            <CenterBlockSlideItem
              key={centerBlockListItems[6].title}
              title={centerBlockListItems[6].title}
              imgUrl={centerBlockListItems[6].imgUrl}
              url={centerBlockListItems[6].url}
              forwardToCenter={forwardTo}
            />
          </div>
          <div className="center_deux_col_mob">
            <CenterBlockSlideItem
              key={centerBlockListItems[0].title}
              title={centerBlockListItems[0].title}
              imgUrl={centerBlockListItems[0].imgUrl}
              url={centerBlockListItems[0].url}
              forwardToCenter={forwardTo}
            />
            <CenterBlockSlideItem
              key={centerBlockListItems[1].title}
              title={centerBlockListItems[1].title}
              imgUrl={centerBlockListItems[1].imgUrl}
              url={centerBlockListItems[1].url}
              forwardToCenter={forwardTo}
            />
          </div>
          <div className="center_deux_col_mob">
            <CenterBlockSlideItem
              key={centerBlockListItems[2].title}
              title={centerBlockListItems[2].title}
              imgUrl={centerBlockListItems[2].imgUrl}
              url={centerBlockListItems[2].url}
              forwardToCenter={forwardTo}
            />
            <CenterBlockSlideItem
              key={centerBlockListItems[3].title}
              title={centerBlockListItems[3].title}
              imgUrl={centerBlockListItems[3].imgUrl}
              url={centerBlockListItems[3].url}
              forwardToCenter={forwardTo}
            />
          </div>
          <div className="center_deux_col_mob">
            <CenterBlockSlideItem
              key={centerBlockListItems[4].title}
              title={centerBlockListItems[4].title}
              imgUrl={centerBlockListItems[4].imgUrl}
              url={centerBlockListItems[4].url}
              forwardToCenter={forwardTo}
            />
            <CenterBlockSlideItem
              key={centerBlockListItems[5].title}
              title={centerBlockListItems[5].title}
              imgUrl={centerBlockListItems[5].imgUrl}
              url={centerBlockListItems[5].url}
              forwardToCenter={forwardTo}
            />
          </div>

          {/* <Slider {...settings} className="center-block-slider">
            {centerBlockListItems.map((centerBlockListItem) => {
              if(centerBlockListItem.title !== "Paris" && centerBlockListItem.title !== "Cormeilles-en-Parisis") {
                  return (<CenterBlockSlideItem
                    key={centerBlockListItem.title}
                    title={centerBlockListItem.title}
                    imgUrl={centerBlockListItem.imgUrl}
                    url={centerBlockListItem.url}
                    forwardToCenter={forwardTo}
                  />)
                }else {
                  return null;
                }

            })}
          </Slider> */}
          {/* <Slider {...settings} className="center-block-slider">
            <CenterBlockSlideItem
              key={centerBlockListItems[8].title}
              title={centerBlockListItems[8].title}
              imgUrl={centerBlockListItems[8].imgUrl}
              url={centerBlockListItems[8].url}
              forwardToCenter={forwardTo}
            />

            <CenterBlockSlideItem
              key={centerBlockListItems[6].title}
              title={centerBlockListItems[6].title}
              imgUrl={centerBlockListItems[6].imgUrl}
              url={centerBlockListItems[6].url}
              forwardToCenter={forwardTo}
            />

            <CenterBlockSlideItem
              key={centerBlockListItems[0].title}
              title={centerBlockListItems[0].title}
              imgUrl={centerBlockListItems[0].imgUrl}
              url={centerBlockListItems[0].url}
              forwardToCenter={forwardTo}
            />
            <CenterBlockSlideItem
              key={centerBlockListItems[1].title}
              title={centerBlockListItems[1].title}
              imgUrl={centerBlockListItems[1].imgUrl}
              url={centerBlockListItems[1].url}
              forwardToCenter={forwardTo}
            />
            <CenterBlockSlideItem
              key={centerBlockListItems[2].title}
              title={centerBlockListItems[2].title}
              imgUrl={centerBlockListItems[2].imgUrl}
              url={centerBlockListItems[2].url}
              forwardToCenter={forwardTo}
            />
            <CenterBlockSlideItem
              key={centerBlockListItems[3].title}
              title={centerBlockListItems[3].title}
              imgUrl={centerBlockListItems[3].imgUrl}
              url={centerBlockListItems[3].url}
              forwardToCenter={forwardTo}
            />
            <CenterBlockSlideItem
              key={centerBlockListItems[4].title}
              title={centerBlockListItems[4].title}
              imgUrl={centerBlockListItems[4].imgUrl}
              url={centerBlockListItems[4].url}
              forwardToCenter={forwardTo}
            />
            <CenterBlockSlideItem
              key={centerBlockListItems[5].title}
              title={centerBlockListItems[5].title}
              imgUrl={centerBlockListItems[5].imgUrl}
              url={centerBlockListItems[5].url}
              forwardToCenter={forwardTo}
            />

          </Slider> */}
        </div>
      </div>
    </div>
  );
};

export default CenterBlock;
