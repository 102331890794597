import React from 'react';
import AwesomeSlider from 'react-awesome-slider';

import HeroContent from './HeroContent';

import './heroCarouselHeader.scss'

const HeroCarouselHeader = (props) => (
  <div>
    <AwesomeSlider
      organicArrows={false}
      className="hero-carousel"
    >
      {props.carouselItems.map((item) => (
        <div key={item} className="hero-carousel-item">
          <img src={item} alt="" />
        </div>
      ))}
    </AwesomeSlider>
    <HeroContent heroHeading={props.heroHeading} heroLink={props.heroLink} />
  </div>
);

export default HeroCarouselHeader;
