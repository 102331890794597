import React, { useRef, useState, useEffect } from 'react';
import YouTube from 'react-youtube';

import getYoutubeVideoId from 'utils/getYoutubeVideoId';
import HeroContent from './HeroContent';

import './header.scss';

const opts = {
  playerVars: {
    autoplay: 1,
    mute: 1,
    loop: 1,
    controls: 0,
    showInfo: 0,
    rel: 0,
    playsinline: 1,
  },
};

const HeroVideoHeader = (props) => {
  const onReady = (e) => {
    e.target.playVideo();
  };

  const onEnd = (e) => {
    e.target.playVideo();
  };

  return (
    <div className="video-background">
      <div className="video-foreground">
        <YouTube onEnd={onEnd} videoId={getYoutubeVideoId(props.videoUrl)} opts={opts} onReady={onReady} />
      </div>
      <HeroContent heroHeading={props.heroHeading} heroLink={props.heroLink} />
    </div>
  );
};

export default HeroVideoHeader;
