import React, { useState, useEffect } from 'react';

import CaliceoLink from 'components/buttons/CaliceoLink';
import useOurUniverseContent from 'hooks/use-our-universe-content';

import BainsIcon from 'images/icons/bains.svg';
import SpaIcon from 'images/icons/spa.svg';
import VitaliteIcon from 'images/icons/vitalite.svg';

import useWindowWidth from 'hooks/use-window-width';

import './ourUniverse.scss';

import { createTempUrl } from 'utils/createTempUrl';

const OurUniverse = () => {
  const {
    universeMainContent,
    universeCards,
  } = useOurUniverseContent();

  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  const getCardIcon = (cardType) => {
    if (cardType === 'SPA') {
      return SpaIcon;
    }
    if (cardType === 'Vitalite') {
      return VitaliteIcon;
    }
    return BainsIcon;
  };

  return (
    <div className="container our-universe--section">
      <h2 className="our-universe--title">{universeMainContent.title}</h2>
      <h3 className="our-universe--subtitle">{universeMainContent.subtitle}</h3>
      {windowWidth < 769 ? (
        <div className="our-universe-mobile--row">
          {universeCards.map((universeCard) => {
            if (!universeCard.title) return null;
            return (
              <a href={universeCard.link.url} >
                <div className="universe-card" key={universeCard.title}>
                    <img src={universeCard.imgUrl} alt={universeCard.title} />
                    <h3>{universeCard.title}</h3>
                </div>
              </a>
            );
          })}
        </div>
      ) : (
        <div className="our-universe--row">
          {universeCards.map((universeCard) => {
            if (!universeCard.title) return null;
            return (
              <div className="our-universe-card--wrapper" key={universeCard.title}>
                <div className="our-universe-card--image--wrapper">
                  <img src={universeCard.imgUrl} alt={universeCard.title} />
                </div>
                <div className="our-universe-card--content">
                  <div className="our-universe-card--content-body">
                    <h3 className="our-universe-card--title">
                      {universeCard.title}
                      <img src={getCardIcon(universeCard.title)} alt="Universe card icon" />
                    </h3>
                    <p className="our-universe-card--subtitle">{universeCard.subtitle}</p>
                    <div className="our-universe-card--description" dangerouslySetInnerHTML={{ __html: universeCard.description }} />
                    <CaliceoLink
                      primaryButton
                      classNames="our-universe-card--button"
                      url={universeCard.link.url}
                      title={universeCard.link.title}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

    </div>
  );
};

export default OurUniverse;
