import gql from 'graphql-tag';

const QUICK_ACCESS_BLOCK_QUERY = gql`
  {
    blockContentById(id: "4") {
      ... on BlockContentReferencesSimples {
        entityId
        entityLabel
        fieldContenuLien {
          url {
            path
          }
          uri
        }
      }
    }
  }
`;

export default QUICK_ACCESS_BLOCK_QUERY;
