import { useStaticQuery, graphql } from 'gatsby';

const useLandingHeaderContent = () => {
  const { caliceo } = useStaticQuery(graphql`
    query {
      caliceo {
        blockContentById(id: "1") {
          ... on caliceo_BlockContentConcept {
            entityId
            entityLabel
            fieldDiapositive {
              entity {
                entityLabel
              }
            }
            fieldVideo {
              entity {
                ... on caliceo_MediaRemoteVideo {
                  fieldMediaOembedVideo
                }
              }
            }
            fieldLien {
              title
              url {
                path
              }
            }  
            fieldTitre
          }
        }
      }
    }
  `);

  return {
    landingData: {
      landingVideo: caliceo.blockContentById.fieldVideo,
      landingImages: caliceo.blockContentById.fieldDiapositive,
      landingTitle: caliceo.blockContentById.fieldTitre,
      landingUrl: caliceo.blockContentById.fieldLien?.url?.path,
    },
  };
};

export default useLandingHeaderContent;
