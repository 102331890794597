import React from 'react';

import CaliceoLink from 'components/buttons/CaliceoLink';
import CaliceoLogo from 'images/caliceo-menu-logo.svg'

const HeroContent = (props) => {
  return (
    <div className="hero-content">
      <h2>{props.heroHeading}</h2>
      {props.heroLink ? (
      <CaliceoLink
        classNames="hero-content--link"
        primaryButton
        url={props.heroLink}
      />):('')
      }
      <img src={CaliceoLogo} className="hero-content--logo" alt="Caliceo" />
    </div>
  );
};

export default HeroContent;
