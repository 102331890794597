import React from 'react';
import HeroContent from './HeroContent';

const HeroImageHeader = (props) => (
  <div className="hero-image--wrapper">
    <img className="hero-image" src={props.heroImage} alt={props.heroHeading} />
    <HeroContent heroHeading={props.heroHeading} heroLink={props.heroLink} />
  </div>
)

export default HeroImageHeader;
