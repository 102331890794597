import React from 'react';

const CenterBlockSlideItem = (props) => {
  const {
    url, imgUrl, title, forwardToCenter
  } = props;

  return (
    <div className="center-block-slide--content" role="presentation" onClick={() => forwardToCenter(url)}>
      <img className="center-block-slide--item--image" src={imgUrl} alt={`Center-${title}`} />
      <h3 className="center-block-slide--item--title">{title}</h3>
    </div>
  );
};

export default CenterBlockSlideItem;
